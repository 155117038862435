<template>
  <div>
    <div class="info1">
      <div class="top1">
        <div class="leftTop1">
          <van-nav-bar left-text="" left-arrow @click-left="onClickLeft"/>
        </div>
        <div class="rightTop1">
          <span class="title1">《热血大乱斗》账号注销</span>
        </div>
      </div>
      <div class="cont2">

      </div>
      <van-form @submit="onSubmit">
        <!--账号-->
        <van-field v-model="account" class="account" name="account" label="账号" placeholder="请输入账号" :rules="[{ required: true, message: '请输入账号'},]"></van-field>
        <!--密码-->
        <van-field v-model="password" class="password" name="password" label="密码" placeholder="请输入密码" :rules="[{ required: true, message: '请输入密码'} ]"></van-field>
        <div style="text-align: center">
          <van-button class="login" type="danger">登录</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {NavBar, Toast} from 'vant';
import {Base64} from "js-base64";
Vue.use(NavBar);
import {
  checkUserInfo,
} from "@/api/common/user";
export default {
  data() {
    return {
      first_name:'',
      last_name:'',
      email:'',
      account: "", //账号
      password: "", //密码
      user_id: 0, //用户id
      user_email: null, //用户id
      game_id: "", //游戏id
      delState: false, //防止重复点击
    };
  },
  methods: {
    onClickLeft() {
      //  同意后跳转到删除账号页面
      this.$router.push({
        path: '/delusers'
      });
    },
    //  账号验证通过-跳转地址
    redirectUrl() {
      //  输入之后跳转到提示页面
      const key = Base64.encode(JSON.stringify({account: this.account, password: this.password}))
      this.$router.push({
        path: '/deluser/rxdld/tips',
        query:{key: key}
      });
    },
    //  开始验证账号
    checkUserInfo() {
      console.log("获取账号：" + this.account)
      console.log("获取账号密码：" + this.password)
      checkUserInfo({account:this.account, password: this.password}).then((response) => {
        //  验证成功区间
        Toast.clear(true)
        this.delState = false
        console.log('验证成功区间，response：' + JSON.stringify(response))
        //  验证成功-开始跳转到成功提示页面
        this.redirectUrl()
      }).catch((response) => {
        //  验证失败区间
        this.delState = false
        if (response.response.status != undefined && response.response.status === 422) {
          // 处理422错误码的逻辑
          this.$toast.fai(response.response.data.message);
        } else {
          this.$toast.fail(response.message)
        }
      });
    },
    onSubmit() {
      const that = this;
      if (that.delState) {
        return false;
      }
      that.delState = true;
      Toast.loading({
        message: "正在登录中",
        forbidClick: true,
      });
      this.checkUserInfo();
    },
  },
};
</script>

<style>

.info1{
  margin: 0.2rem 0.2rem;
  border: 1px solid #333;
}
.top1{
  font-size: 0.2rem;
  font-weight: bold;
  top: 10%;
  text-align: center;
  border-bottom: 2px solid #333;
  width: 100%;
  height: 0.4rem;
}

.leftTop1{
  width: 10%;
  display: inline-block;
  font-size: large;
}
.rightTop1{
  width: 90%;
  display: inline-block;
  margin-top: 0.1rem;
}

.title1{
  font-weight: bold;
  font-size: 15px;
}

.van-nav-bar__left, .van-nav-bar__right {
  position: absolute;
  top: -13px;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 16px;
  font-size: 10rem;
  cursor: pointer;
}
.van-nav-bar .van-icon {
  color: black;
  font-size: x-large;
}
.van-nav-bar__content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
}
.cont2{
  font-size: 0.15rem;
  margin-top: 0.5rem;
}

.loginTitle{
  width: 99%;
  height: 0.35rem;
  line-height: 0.35rem;
  text-align: center;
  border: 2px solid #797979;
  margin-top: 1rem;
}

.van-field__label{
  border: 1px solid black;
  width: 15%;
  text-align: center;
  height: 0.4rem;
  line-height: 0.4rem;
  color: black;
}

.login{
  width: 50%;
  border-color: #02A7F0;
  background-color: #02A7F0;
  color:black;
  margin-bottom: 0.5rem;
  position: relative;
  top: -0.2rem;
}

</style>
