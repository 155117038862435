//import manager from '@/utils/manager'
import api from '@/utils/api'

// 账号检测
export function checkUserInfo(params)
{
    return api.post('wx/v1/common/user/check', params)
}

// 删除用户[冻结用户]
export function delUserInfo(params)
{
    return api.post('wx/v1/common/user/del', params)
}
